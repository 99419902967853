<template lang="pug">
	.cookie.cookies-info-shown(:class='{"active" : isShown}')
		h3.h3.mb-0.cookie__title {{ $t('landing.cookieTitle') }}
		span.b1.text-grey {{ $t('landing.cookieText') }}
		a.b1.cookie__link(href='/privacy' target='_blank') {{ $t('landing.cookieDesc') }}
		span.b3.cookie__button(@click='close') {{ $t('social.understand') }}
</template>

<script>
export default {
    name: 'ModalCookie',
    data: () => ({
        isShown: false,
    }),
    created() {
        this.isShown = !this.$cookies.get('cookies-info-shown');
    },
    methods: {
        close() {
            this.isShown = false;
            this.$cookies.set('cookies-info-shown', '1', '1Y');
        },
    },
};
</script>

<style lang="scss" scoped>
@import '~@/assets/css/sass/_mixins.scss';

.cookie {
    z-index: 10;
    position: fixed;
    display: none;
    bottom: 24px;
    left: 24px;
    padding: 20px 28px;
    width: 100%;
    max-width: 457px;
    background-color: var(--foreground-color);
    border-radius: var(--border-radius-rounded);
    border: 1px solid var(--grayscale-dark-line);
    font-family: 'Golos', 'Nunito', sans-serif;
    font-weight: 400;

    @include respond-below(sm) {
        bottom: 6px;
        left: 6px;
        right: 6px;
        width: auto;
    }

    &.active {
        display: block;
    }

    &__title {
        color: var(--main-text-color) !important;
    }

    &__link {
        display: block;
        width: max-content;
        text-decoration: none;
        color: var(--primary-text-color);
        margin: 13px 0;
    }

    &__button {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 42px;
        color: #000000;
        border-radius: var(--border-radius-rounded);
        background-color: var(--brand-golden-yellow);
        cursor: pointer;
        transition: transform 200ms;

        &:hover {
            transform: scale(1.02);
        }
    }
}
</style>
